<template>
  <div>
    <div class="e-breadcrumb">
      <span style="margin-left: -10px" @click="closeEvent">服务项目</span>
      > 编辑
    </div>
    <div class="order-table-wrapper">
      <div class="e-card">
    <el-form ref="form" label-width="200px" style="margin-top: 30px">
      <el-form-item size="small" label="项目编号:" required>
        <el-input v-model="item.itemNo" placeholder="请输入项目编号" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="项目名称:" required>
        <el-input v-model="item.itemName" placeholder="请输入项目名称" maxlength="64" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="分类:" required>
        <el-select size="small" style="width: 405px" v-model="item.categoryId" placeholder="请选择">
          <el-option
            v-for="item in categoryList"
            :key="item.id"
            :label="item.categoryName"
            :value="item.id">
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item size="small" label="排序:" required>
        <el-input v-model="item.sort" placeholder="请输入排序" maxlength="32" show-word-limit
                  style="width: 405px"></el-input>
      </el-form-item>
      <el-form-item size="small" label="类别:" required>
        <el-radio-group v-model="item.itemType">
          <el-radio :label="0">小项</el-radio>
          <el-radio :label="1">大项</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="回访:" required>
        <el-radio-group v-model="item.returnVisit">
          <el-radio :label="0">不发送</el-radio>
          <el-radio :label="1">发送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="提醒:" required>
        <el-radio-group v-model="item.remind">
          <el-radio :label="0">不发送</el-radio>
          <el-radio :label="1">发送</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="加入套餐:" required>
        <el-radio-group v-model="item.setMeal">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <el-form-item size="small" label="是否自动入单:" required>
        <el-radio-group v-model="item.isAutoEntry">
          <el-radio :label="0">是</el-radio>
          <el-radio :label="1">否</el-radio>
        </el-radio-group>
      </el-form-item>
      <!--<el-form-item size="small" label="适合门店:" required>-->
        <!--<div style="width: 600px">-->
        <!--<el-checkbox v-model="checkAll" @change="checkAllEvent">全选</el-checkbox>-->
        <!--<div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>-->
        <!--<template v-for="(sd,key) in cities">-->
          <!--<div style="margin: 15px 0;"></div>-->
          <!--<el-checkbox v-model="sd.checkAll" @change="handleCheckAllChange(key)">{{sd.name}}-->
          <!--</el-checkbox>-->
          <!--<div style="margin: 15px 0;"></div>-->
          <!--<el-checkbox-group v-model="sd.checkedCities" @change="handleCheckedCitiesChange(key)">-->
            <!--<el-checkbox v-for="item in sd.list" :label="item.id" :key="item.id">{{item.departmentName}}-->
            <!--</el-checkbox>-->
          <!--</el-checkbox-group>-->
          <!--<div style="height:10px;border-bottom:1px #e4e4e4 solid"></div>-->
        <!--</template>-->
      <!--</div>-->
      <!--</el-form-item>-->
      <el-form-item size="small" label="项目图片:" required>
        <div class="graphic-description-value">
          <div>
            <el-upload
              class="e-file-upload"
              :action="Base_url+'/admin/uploadImg'"
              :on-success="imageSuccess1"
              :before-upload="beforeUpload"
              :show-file-list="false"
            >
              <el-image v-if="item.itemImgUrl"
                        style="width: 137px; height: 137px"
                        :src="item.itemImgUrl"
                        fit="contain"></el-image>
              <div class="e-upload--text" v-if="!item.itemImgUrl">
                <span class="e-upload--icon el-icon-plus " style="margin-top: 30px"></span>
                <p>添加上传图片</p>
              </div>
              <p class="e-file-upload-delete" v-if="item.itemImgUrl"
                 @click.stop="item.itemImgUrl=''">
                <i class="el-icon-delete"></i>
              </p>
            </el-upload>
          </div>
        </div>
      </el-form-item>
    </el-form>

    <div class="card-footer" style="width: 600px;margin-bottom: 30px">
      <el-button size="small" @click="closeEvent">取 消</el-button>
      <el-button size="small" type="primary" @click="saveCategory">确 定</el-button>
    </div>
      </div>
    </div>
  </div>
</template>
<script>
  import {Base_url} from '../../config/index'

  export default {
    data() {
      return {
        Base_url: '',
        item: {},

        itemId:'',

        checkAll: false,
        allCheckCities: [], // 全部选中得数据
        cities: {},
        checkTotal: 0,

        categoryList: [],
      }
    },
   async created() {
      this.Base_url = Base_url
      this.itemId = this.$route.query.id
      this.getCategoryList()
     await this.getServiceItem()
     // await this.searchEvent()
    },
    methods: {
      // // 监听 group 选择
      // handleCheckedCitiesChange(key) {
      //   let item = this.cities[key];
      //   item.checkAll = item.ids.length === item.checkedCities.length;
      //   this.getCheckedLen()
      // },
      // // 监听全选
      // handleCheckAllChange(key) {
      //   let item = this.cities[key];
      //   let checkAll = item.checkAll;
      //   item.checkedCities = checkAll ? item.ids : [];
      //   this.getCheckedLen()
      // },
      // checkAllEvent() {
      //   let cities = this.cities;
      //   if (this.checkAll) {
      //     for (let key in  cities) {
      //       let item = cities[key];
      //       item.checkedCities = item.ids;
      //       item.checkAll = true;
      //     }
      //   } else {
      //     for (let key in  cities) {
      //       let item = cities[key];
      //       item.checkAll = false;
      //       item.checkedCities = [];
      //     }
      //   }
      //   this.cities = cities;
      //   this.getAllCheckCityes();
      // },
      // getAllCheckCityes() {
      //   let allCheckCities = [];
      //   for (let key in  this.cities) {
      //     let item = this.cities[key];
      //     allCheckCities = [...allCheckCities, ...item.checkedCities]
      //   }
      //   this.allCheckCities = allCheckCities;
      // },
      // getCheckedLen() {
      //   let len = 0;
      //   for (let key in  this.cities) {
      //     let item = this.cities[key];
      //     len += item.checkedCities.length
      //   }
      //   if (this.checkTotal === len) {
      //     this.checkAll = true;
      //   } else {
      //     this.checkAll = false;
      //   }
      //   this.getAllCheckCityes();
      // },

      async getCategoryList() {
        let data = {
          categoryType: 0
        }
        let res = await this.$get("/admin/getCategoryList", data)
        if (res.code == 200) {
          this.categoryList = res.categoryList
        }
      },
     async getServiceItem(){
       let res = await this.$get("/admin/getServiceItem",{id:this.itemId})
       if (res.code==200){
         this.item =res.serviceItem
         this.allCheckCities=[...this.item.departmentIds]
       }
     },
      //图片相关
      imageSuccess1(res) {
        this.item.itemImgUrl = res.fileUrl
      },
      // 上传文件之前
      beforeUpload(file) {
        const isJPG = file.type === 'image/jpeg';
        const isPNG = file.type === 'image/png';
        const isLt2M = file.size / 1024 / 1024 < 3;

        if (!isJPG && !isPNG) {
          this.$message.error('请上传JPG或PNG格式!');
        }
        if (!isLt2M) {
          this.$message.error('上传大小不能超过 3MB!');
        }
        return (isJPG || isPNG) && isLt2M;
      },

      closeEvent() {
        this.$router.push('/baseData/serviceItem?index=0')
      },

      async saveCategory() {
        if (!this.item.itemNo) {
          this.$message.error("请填写项目编号");
          return
        }
        if (!this.item.itemName) {
          this.$message.error("请填写项目名称");
          return
        }

        if (!this.item.categoryId) {
          this.$message.error("请选择分类");
          return
        }
        if (!this.item.itemImgUrl) {
          this.$message.error("请上传图片");
          return
        }

        let data = {
          id: this.item.id,
          itemNo: this.item.itemNo,
          itemName: this.item.itemName,
          categoryId: this.item.categoryId,
          itemType: this.item.itemType,
          returnVisit: this.item.returnVisit,
          remind: this.item.remind,
          setMeal: this.item.setMeal,
          sort: this.item.sort,
          isAutoEntry: this.item.isAutoEntry,
          itemImgUrl: this.item.itemImgUrl,
          departmentIdStr: JSON.stringify(this.allCheckCities)
        };


        let res = await this.$post("/admin/save_bsServiceItem", data)
        if (res.code == 200) {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'success'
          });
          this.$router.push('/baseData/serviceItem?index=0')
        } else {
          this.$message({
            showClose: true,
            message: res.message,
            type: 'error'
          });
        }
      }
    }
  }
</script>

<style>

</style>
